/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import {
  Container,
  ExpansionPanel,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, Trans, useTranslation } from "@trueskin-web/translations"

import BlogHero from "../block-library/blog-hero"
import BlogRelated from "../block-library/blog-related"
import AuthorMarkup from "../block-library/components/author-markup"
import Blocks from "../block-library/components/blocks"
import BlogPostLink from "../block-library/components/blog-post-link"
import Breadcrumbs from "../block-library/components/breadcrumbs"
import FaqMarkup from "../block-library/components/faq-markup"
import MoreInformationLinks from "../block-library/components/more-information-links"
import SEODetails from "../block-library/components/seo-details"
import FaqContainer from "../components/faq-container"
import Layout from "../components/layout"

import BlogTableOfContent from "./blog-content"

const RelatedArticleCard = ({ readingTime, ...rest }) => (
  <div sx={{ position: "relative", height: "100%" }}>
    <BlogPostLink
      {...rest}
      sx={{
        height: "100%",
      }}
    />

    {typeof readingTime !== "undefined" && readingTime !== null && (
      <div
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          px: 3,
          py: 2,
          color: "background",
          backgroundColor: "black",
          fontSize: 0,
          fontFamily: "monospace",
        }}
      >
        <Trans
          i18nKey="Templates.BlogSubcategory.readingTime"
          time={readingTime}
        />
      </div>
    )}
  </div>
)

const BlogSubcategoryBreadcrumbs = ({ blogSubcategory }) => {
  const { t } = useTranslation()

  return (
    <Breadcrumbs
      items={[
        {
          title: t("Templates.BlogSubcategory.title"),
          href: Slugs["skin-guide"].url,
        },
        {
          title: blogSubcategory.blogCategory.name,
          href: `/${blogSubcategory.blogCategory.slug}`,
        },
        { title: blogSubcategory.name },
      ]}
    />
  )
}

const BlogSubcategory = ({
  data: {
    strapi: { blogSubcategory, blogSubcategoryToc, blogPostsConnection },
  },
}) => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    trackingClient.trackCampaignIfPresent(location)
  }, [location])

  return (
    <Fragment>
      <AuthorMarkup
        url={`${blogSubcategory.blogCategory.slug}/${blogSubcategory.slug}`}
        title={blogSubcategory.heading}
        description={
          blogSubcategory.seoDetails &&
          blogSubcategory.seoDetails.metaDescription
        }
        datePublished={blogSubcategory.createdAt}
        dateModified={blogSubcategory.updatedAt}
      />

      {blogSubcategory.showFaq && (
        <FaqMarkup faqSection={blogSubcategory.faqSection} />
      )}

      <Layout title={blogSubcategory.name}>
        <SEODetails
          title={blogSubcategory.name}
          {...blogSubcategory.seoDetails}
        />

        {blogSubcategory.headerBlock?.length ? (
          <div sx={{ mt: [5, 7], mb: 5 }}>
            <Container>
              <Blocks blocks={blogSubcategory.headerBlock} />
              <BlogSubcategoryBreadcrumbs blogSubcategory={blogSubcategory} />
            </Container>
          </div>
        ) : (
          <BlogHero
            categoryLayout
            {...blogSubcategory.hero}
            sx={{ mb: 5 }}
            breadcrumbs={
              <BlogSubcategoryBreadcrumbs blogSubcategory={blogSubcategory} />
            }
          />
        )}

        <div sx={{ mt: [null, null], mb: 9 }}>
          <Container>
            <BlogTableOfContent
              toc={blogSubcategoryToc}
              blocks={blogSubcategory.blocks}
              author={blogSubcategory.pageAuthor}
              hideTableOfContents={blogSubcategory.hideTableOfContents}
            />

            {/* <Toc toc={blogSubcategoryToc} />

          <Blocks blocks={blogSubcategory.blocks} /> */}

            {/* <Heading as="h3" mb={5}>
          Ausgewählte Artikel
        </Heading> */}

            <BlogRelated
              heading={t("Templates.BlogSubcategory.relatedArticles", {
                count: blogPostsConnection.values.length,
              })}
              headingProps={{
                as: "h3",
                sx: {
                  width: ["calc(100% + 32px)", "100%"],
                  mx: [-4, 0],
                  textAlign: "center",
                  backgroundColor: blogSubcategory.blogCategory.backgroundColor,
                  py: "18px",
                  my: 7,
                },
              }}
              backgroundColor={blogSubcategory.blogCategory.backgroundColor}
              component={RelatedArticleCard}
              items={blogPostsConnection.values.map((e) => ({
                title: e.title,
                blogPost: e,
                blogSubcategory,
              }))}
            />
          </Container>
        </div>

        {blogSubcategory.showFaq &&
          blogSubcategory.faqSection &&
          blogSubcategory.faqSection.length && (
            <div sx={{ mt: [null, null], mb: 9 }}>
              <Container>
                <FaqContainer>
                  {blogSubcategory.faqSection.map(
                    ({ question, answer }, index) => (
                      <ExpansionPanel key={index} title={question}>
                        <UserGeneratedHtml html={answer} />
                      </ExpansionPanel>
                    )
                  )}
                </FaqContainer>
              </Container>
            </div>
          )}

        <MoreInformationLinks linksHtml={blogSubcategory.preFooterLinks} />
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query ($blogSubcategoryId: ID!) {
    strapi {
      blogSubcategoryToc(where: { id: $blogSubcategoryId })
      blogSubcategory(id: $blogSubcategoryId) {
        blogCategory {
          name
          slug
        }
        name
        slug
        heading
        description
        hideTableOfContents
        showFaq
        faqSection {
          question
          answer
        }
        updatedAt
        createdAt
        preFooterLinks
        featuredPosts {
          title
          slug
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 500, layout: CONSTRAINED)
              }
            }
          }
          readingTime
        }
        pageAuthor {
          title
          description
          doctor {
            name
            specialty
            slug
            image {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        morePosts {
          title
          slug
          updatedAt
        }
        hero {
          ...BlogHeroFragment
        }
        headerBlock {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
        }
        blocks {
          __typename
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentBlogVideo {
            ...VideoFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksBlogPitch {
            ...BlogPitchFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
          ... on Strapi_ComponentBlocksNewsletterSignup {
            ...NewsletterSignupFragment
          }
        }
        seoTitle
        seoContent
        seoDetails {
          ...SEODetailsFragment
        }
        blogCategory {
          backgroundColor
        }
      }
      blogPostsConnection(
        where: { blogSubcategory: { id: $blogSubcategoryId } }
      ) {
        values {
          id
          title
          slug
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 500, aspectRatio: 1, layout: CONSTRAINED)
              }
            }
          }
          readingTime
        }
      }
    }
  }
`

export default BlogSubcategory
